import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const AppointmentsPageTemplate = ({
  title,
  helmet,
  content,
  contentComponent,
  appointments,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content content-border">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr />
            <PostContent content={content} />
            <hr />
            <Link to="/locations">
              <p className="has-text-primary">
                <strong>
                  Find contact numbers, addresses, and opening hours for our
                  collection facilities.
                </strong>
              </p>
            </Link>
            <hr />
            {appointments && appointments.length ? (
              <div>
                {appointments.map((appointment) => (
                  <div key={appointment.test + `appointment`}>
                    <h3>{appointment.test}</h3>
                    <p>{appointment.description}</p>
                    <ul>
                      <li>Bay of Plenty - {appointment.bop}</li>
                      <li>Hamilton - {appointment.ham}</li>
                      <li>Rotorua - {appointment.rot}</li>
                      <li>Whakatane - {appointment.whk}</li>
                      <li>Taupo - {appointment.tpo}</li>
                    </ul>
                    <hr />
                  </div>
                ))}
              </div>
            ) : null}
            Please note that we cannot offer urine drug testing for
            pre-employment, return to work, or drivers licence purposes. Contact
            TTDA - The Drug Detection Agency.
            <hr />
          </div>
        </div>
      </div>
    </section>
  );
};

AppointmentsPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      test: PropTypes.string,
      description: PropTypes.string,
      bop: PropTypes.string,
      ham: PropTypes.string,
      rot: PropTypes.string,
      whk: PropTypes.string,
      tpo: PropTypes.string,
    })
  ),
};

const Appointments = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AppointmentsPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Pathlab">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        content={post.html}
        contentComponent={HTMLContent}
        appointments={post.frontmatter.appointments}
      />
    </Layout>
  );
};

Appointments.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Appointments;

export const pageQuery = graphql`
  query AppointmentsByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        appointments {
          test
          description
          bop
          ham
          rot
          whk
          tpo
        }
      }
    }
  }
`;
